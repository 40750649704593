<script>
import { mapActions, mapGetters } from 'vuex'

import FileUploader from '../../base/FileUploader.vue'
import ExerciseForm from './ExerciseForm.vue'
import { mdiPlus, mdiDotsVertical } from '@mdi/js'
export default {
  name: 'DayForm',
  components: {
    FileUploader,
    ExerciseForm,
  },
  props: {
    id: Number,
    day: Number,
    categoryId: Number,
    exerciseCategories: Array,
    thumbnail: {
      type: [File, Object],
      default: function () {
        return null
      },
    },
    contents: Array,
    customName: String,
    errors: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDotsVertical,
      },
      selectedExercise: null,
    }
  },
  computed: {
    ...mapGetters({
      exercises: 'exercise/contentExercises',
      altExercises: 'exercise/contentAltExercises',
    }),
  },
  async mounted() {
    await this.getExercises()
  },
  methods: {
    ...mapActions({
      getExercises: 'exercise/getExercises',
    }),
    search(val) {
      this.getExercises({ search: val })
    },
    addContent() {
      let newContents = [...this.contents]

      newContents.push({
        exercise: this.exercises.find((e) => e.id === this.selectedExercise),
        exercise_video_id: this.selectedExercise,
        alternative_exercises: [],
        number_of_sets: null,
        number_of_reps: null,
        intensity_technique: null,
        super_set_label: null,
        rest: null,
        tempo: null,
      })

      this.selectedExercise = null

      this.$emit('input:contents', { contents: newContents })
    },
    updateContent(index, key, value) {
      let newContents = [...this.contents]
      newContents[index][key] = value

      this.$emit('input:contents', {
        contents: newContents,
        extraField: `.${index}.${key}`,
      })
    },
    onRemoveDay(index) {
      this.$emit('input:contents', {
        contents: [...this.contents].filter((item, idx) => idx !== index),
      })
    },
    duplicateDay(index) {
      const item = this.contents.find((item, idx) => idx === index)

      this.$emit('input:contents', {
        contents: [...this.contents, { ...item, id: null }],
      })
    },
  },
}
</script>

<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="3">
        <v-text-field
          type="number"
          outlined
          required
          label="Day"
          prefix="Day"
          min="0"
          :value="day"
          @input="$emit('input:day', $event)"
          :error-messages="errors[`daily_exercises.${id}.day`]"
        ></v-text-field>
      </v-col>

      <!-- <v-col cols="4">
        <v-select
          :value="categoryId"
          @input="$emit('input:categoryId', $event)"
          class="input__outlined--regular"
          label="Category"
          outlined
          :items="exerciseCategories"
          item-value="id"
          item-text="label"
          :error-messages="errors[`daily_exercises.${id}.category_id`]"
        />
      </v-col> -->

      <v-col cols="9">
        <v-text-field
          outlined
          required
          label="Custom Name"
          :value="customName"
          @input="$emit('input:customName', $event)"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row style="border-bottom: 1px solid #e6e6e9">
      <v-col>
        <file-uploader
          :value="thumbnail"
          @input="$emit('input:thumbnail', $event)"
          accept="image/*"
          :initial-preview-url="thumbnail?.url"
        />
      </v-col>
    </v-row>

    <v-row class="ma-0 pt-5">
      <v-col class="pa-0">
        <p class="text-tertiary text-base ma-0">Exercises</p>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <v-autocomplete
            v-model="selectedExercise"
            :items="exercises"
            item-text="title"
            item-value="id"
            @update:search-input="search"
            class="input__outlined--regular mr-4"
            outlined
            hide-details
          ></v-autocomplete>

          <v-btn
            outlined
            color="primary"
            large
            @click="addContent"
            :disabled="!selectedExercise"
          >
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Exercise</span>
          </v-btn>
        </div>
      </v-col>

      <v-col
        class="py-0"
        cols="12"
        v-if="errors[`daily_exercises.${id}.contents`]"
      >
        <div class="v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div
                v-for="error in errors[`daily_exercises.${id}.contents`]"
                :key="`content-errors-${error}`"
                class="v-messages__message"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion flat>
          <v-expansion-panel
            v-for="(content, i) in contents"
            :key="`daily_exercises_${id}_contents_${i}`"
            class="daily-exercises__contents"
          >
            <v-expansion-panel-header>
              {{ content.exercise?.title }}

              <template v-slot:actions>
                <div>
                  <v-menu offset-y offset-x bottom left max-width="100">
                    <template v-slot:activator="{ on, attrs }">
                      <button v-bind="attrs" v-on="on">
                        <v-icon>
                          {{ icons.mdiDotsVertical }}
                        </v-icon>
                      </button>
                    </template>

                    <div style="background: white">
                      <p
                        class="mb-0 py-2 px-4 text-xs font-medium clickable"
                        @click="onRemoveDay(i)"
                      >
                        Delete
                      </p>
                      <p
                        class="mb-0 py-2 px-4 text-xs font-medium clickable"
                        @click="duplicateDay(i)"
                      >
                        Duplicate
                      </p>
                    </div>
                  </v-menu>
                </div>
              </template>
            </v-expansion-panel-header>

            <exercise-form
              :day-index="id"
              :content-index="i"
              :no-of-sets="content.number_of_sets"
              :no-of-reps="content.number_of_reps"
              :intensity-technique="content.intensity_technique"
              :super-set-label="content.super_set_label"
              :alternative-exercises="content.alternative_exercises"
              :rest="content.rest"
              :tempo="content.tempo"
              :errors="errors"
              @input:no-of-sets="
                (value) => updateContent(i, 'number_of_sets', value)
              "
              @input:no-of-reps="
                (value) => updateContent(i, 'number_of_reps', value)
              "
              @input:intensity-technique="
                (value) => updateContent(i, 'intensity_technique', value)
              "
              @input:super-set-label="
                (value) => updateContent(i, 'super_set_label', value)
              "
              @input:rest="(value) => updateContent(i, 'rest', value)"
              @input:tempo="(value) => updateContent(i, 'tempo', value)"
              @input:alternativeExercises="
                (value) => updateContent(i, 'alternative_exercises', value)
              "
            />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
</template>

<style scoped lang="scss">
.daily-exercises__contents {
  border: 1px solid #e6e6e9;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
